import header from './header';
import contents from './contents';
import next from './next';
import footer from './footer';
import members from './members';

const items = [
    {
        name: 'Guideline Development Group',
        link: '/members.html',
        newTab: false,
    },
    {
        name: 'Summary of Recommendations',
        link: 'https://dl.dropboxusercontent.com/scl/fi/6tzrzf2hacm3k5utjg9b8/Summary-of-recommendations.docx?rlkey=jlmd5qglfdc5yc4nah5whyxn1&st=cnwjhtx0',
        newTab: true,
    },
    {
        name: 'Technical Report',
        link: 'https://dl.dropboxusercontent.com/scl/fi/naxewmtz527e4674jj3ql/Technical-report.docx?rlkey=seprrzi2llvjh7yi2pfjmqmpj&st=820vlq6h',
        newTab: true,
    },
    {
        name: 'Administrative Report',
        link: 'https://dl.dropboxusercontent.com/scl/fi/vp65qhxph0osrcdypgbii/ADMINISTRATIVE-REPORT.docx?rlkey=uh0tmq0r4zkluwtvebhyovzjx&st=g4kczfvw',
        newTab: true,
    },
    {
        name: 'Dissemination and Implementation Plan',
        link: 'https://dl.dropboxusercontent.com/scl/fi/m3newroq0kp3chdqg2sf5/Dissemination-and-Implementation-Plan.docx?rlkey=kln82zeyhvs3z09xmdwzm60uk&st=65ykavq8',
        newTab: true,
    },
];

header(items);
contents();
next();
footer(items);
members();
