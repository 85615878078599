import React from 'react';
import {createRoot} from 'react-dom/client';
import styles from './styles.module.css';
import {flattenPageContent} from '../flattenPageContent';

const Next = () => {
    const [nextPage, setNextPage] = React.useState<{
        name: string;
        link: string;
    }>();

    React.useEffect(() => {
        void import('../../page-content.json').then(data => {
            const currentLink = window.location.pathname;
            // For testing
            // const currentLink =
            //     '/06-alimentary-tract-and-metabolism/01-proton-pump-inhibitors.html';

            const pageContent = flattenPageContent(data.default);

            const currentIndex = pageContent.findIndex(
                o => o.link === currentLink,
            );
            const nextPageData = pageContent[currentIndex + 1];
            if (nextPageData.link) {
                setNextPage({
                    name: nextPageData.name,
                    link: nextPageData.link,
                });
            }
        });
    }, []);

    if (!nextPage) {
        return null;
    }

    return (
        <div className={styles.container}>
            <a className={styles.box} href={nextPage.link}>
                <b>{nextPage.name}</b>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className={'bi bi-arrow-right'}
                    viewBox="0 0 16 16"
                >
                    <path
                        fillRule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                </svg>
            </a>
        </div>
    );
};

export default () => {
    const domNode = document.getElementById('next');
    if (domNode) {
        const root = createRoot(domNode);
        root.render(<Next />);
    }
};
