import {createRoot} from 'react-dom/client';
import memberPhotos from '../../member-photos.json';
import styles from './styles.module.css';

const Members = () => {
    return (
        <div className={styles.container}>
            {memberPhotos.map(o => (
                <div className={styles.item} key={o.name}>
                    <div
                        className={styles.itemImage}
                        style={{
                            backgroundImage: `url('/images/members/${o.filePath}')`,
                        }}
                    />
                    <p>
                        <b>
                            {o.link ? (
                                <a href={o.link} target="_blank">
                                    {o.name}
                                </a>
                            ) : (
                                o.name
                            )}
                        </b>
                    </p>
                    <p>{o.title}</p>
                </div>
            ))}
        </div>
    );
};

export default () => {
    const domNode = document.getElementById('members');
    if (domNode) {
        const root = createRoot(domNode);
        root.render(<Members />);
    }
};
