import * as React from 'react';
import styles from './styles.module.css';
import rewriteKeywords from './rewrite-keywords';

export default () => {
    const [value, setValue] = React.useState('');
    const [searchPages, setSearchPages] = React.useState<
        {name: string; link: string; content: string}[]
    >([]);
    const [pages, setPages] = React.useState<
        {name: string; link: string; content: string}[]
    >([]);

    React.useEffect(() => {
        const flatPages: {name: string; link: string; content: string}[] = [];
        void import('../../../page-content.json').then(data => {
            const pageContent = data.default;
            pageContent.forEach(pageOrDir => {
                if (pageOrDir.content) {
                    flatPages.push(pageOrDir);
                } else if (pageOrDir.pages) {
                    pageOrDir.pages.forEach(nestedPages => {
                        if (nestedPages.content) {
                            flatPages.push(nestedPages);
                        }
                    });
                }
            });
        });
        setSearchPages(flatPages);
    }, []);

    React.useEffect(() => {
        const trimmedValue = value.trim();
        if (trimmedValue.length === 0) {
            setPages([]);
            return;
        }

        const filteredPages = searchPages.filter(page => {
            if (page.content?.includes(trimmedValue)) {
                return true;
            }

            const foundRewriteWord = rewriteKeywords.find(o =>
                o.keywords.includes(trimmedValue),
            );
            if (
                foundRewriteWord &&
                page.content.includes(foundRewriteWord.rewrite)
            ) {
                return true;
            }

            return false;
        });

        setPages(filteredPages);
    }, [value]);

    if (!searchPages) {
        return null;
    }

    const foundPages = pages.length > 0;

    return (
        <div
            className={`${styles.container} ${foundPages ? styles.containerOpen : ''}`}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
            >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
            </svg>
            <input
                className={styles.input}
                value={value}
                onChange={e => {
                    setValue(e.currentTarget.value);
                }}
                placeholder="Search"
            />
            {foundPages && (
                <div className={styles.dropdown}>
                    {pages.map(page => (
                        <a
                            key={page.link}
                            className={styles.dropdownItem}
                            href={page.link}
                        >
                            {page.name}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className={'bi bi-arrow-right'}
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                                />
                            </svg>
                        </a>
                    ))}
                </div>
            )}
        </div>
    );
};
