type PageItem = {
    name: string;
    link: string;
    content: string;
    pages?: Item[];
};

type Item =
    | PageItem
    | {
          name: string;
          pages: Item[];
          link?: undefined;
      };

type Content = Item[];

export const flattenPageContent = (pageContent: Content) => {
    const flatContents: PageItem[] = [];

    pageContent.forEach(item => {
        if (item.link) {
            flatContents.push(item);
        } else if (item.pages) {
            const nestedItems = flattenPageContent(item.pages);
            flatContents.push(...nestedItems);
        }
    });

    return flatContents;
};
