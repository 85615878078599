import React, {FormEvent} from 'react';
import styles from './styles.module.css';

const password = 'gdg2025';
const passwordKey = 'gdg-password';

const storedPassword = window.localStorage.getItem(passwordKey);

export default () => {
    const [passwordEnabled, setPasswordEnabled] = React.useState(
        storedPassword !== password,
    );
    const [value, setValue] = React.useState('');

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (value === password) {
            window.localStorage.setItem(passwordKey, value);
            setPasswordEnabled(false);
        }
    };

    React.useEffect(() => {
        if (passwordEnabled) {
            document.body.style = 'overflow: hidden';
        } else {
            document.body.style = 'overflow: auto';
        }
    }, [passwordEnabled]);

    if (!passwordEnabled) return null;

    return (
        <form onSubmit={handleSubmit} className={styles.container}>
            <label htmlFor="password">This site is under development</label>
            <input
                id="password"
                type="password"
                placeholder="Enter the password"
                value={value}
                onChange={e => {
                    setValue(e.currentTarget.value);
                }}
            />
            <button>Submit</button>
        </form>
    );
};
