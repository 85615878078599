import {createRoot} from 'react-dom/client';
import styles from './styles.module.css';

type Items = {name: string; link: string; newTab: boolean}[];

const Footer = ({items}: {items: Items}) => {
    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                <div className={styles.footerLinks}>
                    {items.map(item => (
                        <a
                            href={item.link}
                            target={item.newTab ? '_blank' : '_self'}
                            className={styles.linkItem}
                            key={item.name}
                        >
                            {item.name}
                        </a>
                    ))}
                </div>
                <div>
                    <b>Contact Us</b>
                    <p className={styles.email}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-envelope-fill"
                            viewBox="0 0 16 16"
                            style={{paddingRight: '4px'}}
                        >
                            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
                        </svg>
                        {'deprescribing@uwa.edu.au'}
                    </p>
                    <img
                        className={styles.comImage}
                        src="/images/com.webp"
                        alt="com"
                    />
                    <img
                        className={styles.wachaImage}
                        src="/images/WACHA-1.svg"
                        alt="wacha"
                    />
                </div>
            </div>
        </div>
    );
};

export default (items: Items) => {
    const domNode = document.getElementById('footer');
    if (domNode) {
        const root = createRoot(domNode);
        root.render(<Footer items={items} />);
    }
};
