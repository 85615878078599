import React from 'react';
import {createRoot} from 'react-dom/client';
import styles from './styles.module.css';

const sections = document.getElementsByTagName('h2');

const Contents = () => {
    const [top, setTop] = React.useState<number>();
    const sectionElements = [];
    for (let i = 0; i < sections.length; i++) {
        sectionElements.push(sections.item(i));
    }

    React.useEffect(() => {
        const findPos = () => {
            const {scrollY} = window;
            const threshold = 472;
            if (scrollY > 472) {
                setTop(0);
            } else {
                const distance = threshold - scrollY;
                setTop(distance);
            }
        };

        document.addEventListener('scroll', findPos);
        findPos();

        return () => {
            document.removeEventListener('scroll', findPos);
        };
    }, []);

    if (typeof top === 'undefined' || sectionElements.length === 0) {
        return null;
    }

    return (
        <div className={styles.container} style={{top}}>
            <b>Contents</b>
            <div className={styles.inner}>
                {sectionElements.map(section => (
                    <a
                        href={`#${section?.getAttribute('id')}`}
                        key={section?.getAttribute('id')}
                    >
                        {section?.textContent}
                    </a>
                ))}
            </div>
        </div>
    );
};

export default () => {
    const domNode = document.getElementById('contents');
    if (domNode) {
        const root = createRoot(domNode);
        root.render(<Contents />);
    }
};
