import React from 'react';
import styles from './styles.module.css';

type Items = {name: string; link: string; newTab: boolean}[];

export default ({items}: {items: Items}) => {
    return (
        <div className={styles.container}>
            {items.map((item, ind) => (
                <React.Fragment key={item.name}>
                    <span className={styles.pipe}>{ind !== 0 && '|'}</span>
                    <a
                        href={item.link}
                        target={item.newTab ? '_blank' : '_self'}
                        className={styles.item}
                    >
                        {item.name}
                    </a>
                </React.Fragment>
            ))}
        </div>
    );
};
